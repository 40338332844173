import clsx from "clsx";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Cart from "../checkout/Cart";
import CartTotal from "../checkout/CartTotal";
import { useAppSelector } from "../hooks";
import Layout from "../layout/Layout";
import ServiceList from "../services/ServiceList";
import ServiceSelector from "../services/ServiceSelector";
import IndexSlider from "../shared/IndexSlider";
import { isHandymanMode, isMobileApp } from "../shared/isHandymanMode";
import { selectCartItems, selectHandymanCartItems } from "../store/cart";

type Params = { id: string }

const SelectServiceTypePage = () => {
    const pestcontrolCartItems = useAppSelector(selectCartItems),
        handymanCartItems = useAppSelector(selectHandymanCartItems),
        [params] = useSearchParams(),
        { id } = useParams<Params>(),
        categoryId = parseInt(id || '0'),
        ht = params.get('handyman'),
        handymanMode = isMobileApp() ? (ht !== null) : isHandymanMode(),
        cartItems = handymanMode ? handymanCartItems : pestcontrolCartItems,
        mobileApp = isMobileApp()

    useEffect(() => {
        localStorage.setItem('handyman_type', ht === null ? '' : ht)
    }, [])
    
    return (
        <div className={clsx(
            "akdbw-pb-10 akdbw-pt-5 md:akdbw-py-10",
            (mobileApp && cartItems.length > 0) && 'akdbw-pb-20'
        )}>
            <ServiceList categoryId={ categoryId } handymanMode={ handymanMode } />
        </div>
    )
}


export default SelectServiceTypePage;