import dayjs from "dayjs"
import Heading from "./Heading"
import { timeSlots } from "../utils/time_slots"
import clsx from "clsx"

type DateTimeSelectionProps = {
    onDateSelected: (v: string) => void
    onTimeSelected: (v: string) => void
    selectedDate: string
    selectedTime: string
}

const DateTimeSelection = ({ onDateSelected, onTimeSelected, selectedDate, selectedTime }: DateTimeSelectionProps) => {
    const tomorrow = dayjs().add(1, 'day'),
        serviceDt = dayjs(selectedDate),
        today = dayjs(),
        dateValid = serviceDt.isAfter(today),
        isTomorrow = serviceDt.format('YYYY-MM-DD') === tomorrow.format('YYYY-MM-DD')

    return (
        <div>
            <Heading size={3}>Which Day Would You Like Us To Come?</Heading>
            <input
                type="date"
                value={ selectedDate }
                min={ tomorrow.format('YYYY-MM-DD') }
                onChange={ ev => {
                    onDateSelected(ev.target.value)
                    const serviceDt = dayjs(ev.target.value),
                        isTomorrow = serviceDt.format('YYYY-MM-DD') === tomorrow.format('YYYY-MM-DD')
                    if (isTomorrow) {
                        onTimeSelected('')
                    }
                } }
                placeholder="Select a date..."
                className="akdbw-h-[38px] akdbw-leading-[38px] akdbw-mt-4 akdbw-w-full akdbw-border akdbw-border-border_gray akdbw-p-2 akdbw-rounded-md akdbw-bg-white akdbw-appearance-none"
            />
            { !dateValid && selectedDate != '' &&
                <div className="akdbw-py-2 akdbw-px-4 akdbw-bg-red-300 akdbw-mt-4 akdbw-text-white akdbw-rounded-md akdbw-border akdbw-border-red-500">
                    Booking on that date is not possible
                </div>
            }
            <Heading className="akdbw-mt-8" size={3}> What Time Would You Like Us To Arrive?</Heading>
            <div className="akdbw-grid akdbw-grid-cols-1 md:akdbw-grid-cols-3 akdbw-gap-3 akdbw-mt-5">
                { (isTomorrow ? timeSlots.slice(3) : timeSlots).map(slot => (
                    <div
                        key={ slot }
                        onClick={ () => onTimeSelected(slot) }
                        className={clsx(
                            "akdbw-border akdbw-border-green akdbw-py-2 akdbw-px-4 akdbw-text-green akdbw-rounded-md akdbw-cursor-pointer hover:akdbw-bg-green hover:akdbw-text-white",
                            slot === selectedTime && 'akdbw-bg-green akdbw-shadow akdbw-text-white'
                        )}
                    >
                        { slot }
                    </div>
                ))}
            </div>
        </div>
    )
}

export default DateTimeSelection