import { useNavigate } from "react-router-dom"

type HomeServiceIconProps = {
	icon: string
	title: string
	action: string
}
export const HomeServiceIcon = ({ icon, title, action }: HomeServiceIconProps) => {
	const navigate = useNavigate()

	return (
		<div
			className="akdbw-text-green akdbw-cursor-pointer"
			onClick={ () => navigate(action) }
		>
				<div className="akdbw-border akdbw-border-green akdbw-p-1 akdbw-inline-block akdbw-rounded-md">
						<img src={ icon } alt="" />
				</div>
				<div className="akdbw-mt-2">{ title }</div>
		</div>
	)
}
