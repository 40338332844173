import { useState } from "react";
import ActionButton from "../shared/ActionButton";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import Progress from "../shared/Progress";
import { Booking, useCancelBookingMutation, useCancelHandymanBookingMutation, useListBookingsQuery, useListHandymanBookingsQuery, useRescheduleBookingMutation } from "../store/bookings";
import { getBookingStatus } from "../utils/booking_status";
import { displayDate } from "../utils/date_display";
import Modal from "../layout/Modal";
import Heading from "../shared/Heading";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import DateTimeSelection from "../shared/DateTimeSelection";
import { FileOpener } from '@capacitor-community/file-opener';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { isMobileApp } from "./isHandymanMode";

const disableBooking = (window as any).onlineBookingWidgetCfg?.disableBooking
const mobileApp = isMobileApp()

type BookingsListProps = {
    handymanMode: boolean
}

export const BookingsList = ({ handymanMode }: BookingsListProps) => {
    const 
        { data: handymanData, isLoading: handymanIsLoading } = useListHandymanBookingsQuery(),
        { data: pestcontrolData, isLoading: pestcontrolIsLoading } = useListBookingsQuery(),
        data = handymanMode ? handymanData : pestcontrolData,
        isLoading = handymanMode ? handymanIsLoading : pestcontrolIsLoading,
        [bookingDetails, setBookingDetails] = useState<Booking | null>(null),
        [bookingToReschedule, setBookingToReschedule] = useState<Booking | null>(null),
        [handymanCancelBooking, handymanCancelBookingParams] = useCancelHandymanBookingMutation(),
        [pestcontrolCancelBooking, pestcontrolCancelBookingParams] = useCancelBookingMutation(),
        cancelBooking = handymanMode ? handymanCancelBooking : pestcontrolCancelBooking,
        cancelBookingParams = handymanMode ? handymanCancelBookingParams : pestcontrolCancelBookingParams,
        [rescheduleBooking, rescheduleBookingParams] = useRescheduleBookingMutation(),
        [rescDate, setRescDate] = useState(''),
        [rescTime, setRescTime] = useState(''),
        [downloadActive, setDownloadActive] = useState(false),
        navigate = useNavigate()

    if (isLoading) {
        return <LoadingIndicator />
    }

    const doCancellation = async (booking: Booking) => {
        if (!window.confirm('Are you sure?')) {
            return;
        }
        await cancelBooking(booking.id).unwrap()
        alert('Booking has been cancelled')
    }

    const doReschedule = async () => {
        if (!bookingToReschedule) {
            return
        }
        const start = `${rescDate} ${rescTime.split('-')[0]}`.trim()
        await rescheduleBooking({ id: bookingToReschedule.id, start }).unwrap
        alert('Booking reschedule request sent')
        setBookingToReschedule(null)
    }

    const openReceipt = async (url: string) => {
        if (mobileApp) {
            setDownloadActive(true)
            const response = await fetch(url);
            const blob = await response.blob();

            // Convert blob to base64
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = async () => {
                const base64Data = reader.result as string;
                // Save the PDF locally
                const fileName = 'downloadedFile.pdf'

                await Filesystem.writeFile({
                    path: fileName,
                    data: base64Data,
                    directory: Directory.Documents,
                    recursive: true
                })

                // Get the URI for the saved file
                const uri = await Filesystem.getUri({
                    directory: Directory.Documents,
                    path: fileName
                })

                setDownloadActive(false)
                FileOpener.open({
                    filePath: uri.uri,
                    contentType: 'application/pdf',
                    openWithDefault: true
                })
            }
        } else {
            window.open(url, '_blank')
        }
    }

    return (
        <>
            { !mobileApp &&
                <div className="akdbw-flex akdbw-flex-row akdbw-justify-between akdbw-items-center">
                    <span className="akdbw-text-4xl akdbw-font-bold">My bookings</span>
                    { !disableBooking &&
                        <ActionButton
                            small={ true }
                            className="akdbw-text-sm"
                            onClick={ () => navigate('/') }
                        >
                            New booking
                        </ActionButton>
                    }
                </div>
            }
            <div className={ clsx(
                "akdbw-w-full akdbw-border-t akdbw-border-light_green",
                !mobileApp && 'akdbw-mt-10'
            )}
            >
                { (downloadActive || isLoading || cancelBookingParams.isLoading || rescheduleBookingParams.isLoading) ? (
                    <LoadingIndicator/>
                ) : (
                    <>
                        { data && data.length === 0 &&
                            <div className="akdbw-text-center akdbw-py-10">
                                You have no bookings yet
                            </div>
                        }
                        { data && data.map(booking => (
                            <div key={ booking.id } className="akdbw-flex akdbw-flex-col md:akdbw-flex-row akdbw-border-b akdbw-border-light_green">
                                <div className="akdbw-p-4 md:akdbw-w-2/12">
                                    { booking.event &&
                                        <div className="akdbw-font-bold">#{ booking.event.id }</div>
                                    }
                                    { booking.booking_items.length === 0 ? (
                                        <ul className="akdbw-text-sm akdbw-list-disc akdbw-list-inside">
                                            { (booking.event?.p_ants === 1) && <li>Ants</li> }
                                            { (booking.event?.p_flying === 1) && <li>Flying insects</li> }
                                            { (booking.event?.p_bed_bugs === 1) && <li>Bed bugs</li> }
                                            { (booking.event?.p_cockroches === 1) && <li>Cockroaches</li> }
                                            { (booking.event?.p_mice === 1) && <li>Mice</li> }
                                            { (booking.event?.p_ticks === 1) && <li>Ticks</li> }
                                            { (booking.event?.p_crickets === 1) && <li>Crickets</li> }
                                            { (booking.event?.p_rats === 1) && <li>Rats</li> }
                                            { (booking.event?.p_fleas === 1) && <li>Fleas</li> }
                                            { (booking.event?.p_silverfish === 1) && <li>Silverfish</li> }
                                        </ul>
                                    ) : (
                                        <div className="akdbw-grid akdbw-grid-cols-3 md:akdbw-grid-cols-2 akdbw-gap-2">
                                            { booking.booking_items.map(item => (
                                                <img
                                                    key={ `${item.service_id}-${item.option_id}` }
                                                    className="akdbw-object-contain"
                                                    src={ item.image }
                                                    alt={ `${item.service} - ${item.option}` }
                                                    title={ `${item.service} - ${item.option}` }
                                                />
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="akdbw-p-4 md:akdbw-w-8/12">
                                    <div className="akdbw-w-full akdbw-grid akdbw-grid-cols-2">
                                        <div className="akdbw-py-4">
                                        { displayDate(booking?.start || '') }
                                        </div>
                                        { booking.price > 0 && !booking.event &&
                                            <div className="akdbw-py-4 akdbw-text-right">
                                                <b>{ booking.price_with_vat } AED</b>
                                            </div>
                                        }
                                    </div>
                                    <div className="akdbw-pt-2 akdbw-pb-5">{ booking.location }</div>
                                    { getBookingStatus(booking) > 0 &&
                                        <Progress
                                            step={ getBookingStatus(booking) }
                                            steps={ [ 'Booking Received', 'Service Provider Assigned', 'Service Delivered' ] }
                                        />
                                    }
                                </div>
                                <div className="akdbw-p-4 akdbw-flex akdbw-flex-col md:akdbw-flex-col akdbw-items-center akdbw-justify-evenly md:akdbw-justify-center md:akdbw-w-[250px]">
                                    { (getBookingStatus(booking) === 2 && booking.id > 0) &&
                                        <ActionButton
                                            danger={ true }
                                            onClick={ () => doCancellation(booking) }
                                            className="akdbw-w-full akdbw-m-2"
                                        >
                                            Cancel
                                        </ActionButton>
                                    }
                                    { getBookingStatus(booking) < 0 ?
                                        <div className="akdbw-text-green akdbw-font-bold">Cancelled</div> :
                                        <>
                                            <ActionButton
                                                inverse={ false }
                                                onClick={ () => setBookingDetails(booking) }
                                                className="md:akdbw-mt-3 akdbw-w-full akdbw-m-2"
                                            >
                                                Details
                                            </ActionButton>
                                            { (getBookingStatus(booking) < 4 && booking.event) &&
                                                <ActionButton
                                                    disabled={ booking.rescheduled }
                                                    inverse={ true }
                                                    onClick={ () => setBookingToReschedule(booking) }
                                                    className="md:akdbw-mt-3 akdbw-w-full akdbw-m-2"
                                                >
                                                    Use My Warranty
                                                </ActionButton>
                                            }
                                        </>
                                    }
																		{ booking.event?.online_payment_status === 'SUCCESS' && booking.event?.payment_card_number !== '' &&
																				<ActionButton
																						inverse={ true }
																						onClick={ () => openReceipt(booking.event?.receipt_url || '') }
																						className="md:akdbw-mt-3 akdbw-w-full akdbw-m-2"
																				>
																						Online payment receipt
																				</ActionButton>
                                    }
																	</div>
                            </div>
                        ))}
                    </>
                )}
            </div>
            <Modal
                visible={ bookingToReschedule !== null }
                onClose={ () => setBookingToReschedule(null) }
                fullscreen={ true }
            >
                <div>
                    <div className="akdbw-font-bold akdbw-text-xl akdbw-text-center">Request Reschedule</div>
                    <br />
                    <br />
                    <DateTimeSelection
                        selectedDate={ rescDate }
                        selectedTime={ rescTime }
                        onDateSelected={ v => setRescDate(v) }
                        onTimeSelected={ v => setRescTime(v) }
                    />
                    <br />
                    <br />
                    <div className="akdbw-flex akdbw-items-center akdbw-p-2 md:akdbw-pt-10 akdbw-shadow-[rgba(0,0,15,0.5)_0px_-5px_10px_-6px] md:akdbw-shadow-none akdbw-border-green akdbw-border-t md:akdbw-border-none akdbw-fixed md:akdbw-relative akdbw-bottom-0 akdbw-left-0 akdbw-w-full akdbw-bg-white akdbw-justify-between">
                        <ActionButton
                            uppercase={ true }
                            onClick={ () => setBookingToReschedule(null) }
                            inverse={ true }
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton
                            uppercase={ true }
                            disabled={ rescDate === '' || rescTime === '' }
                            onClick={ () => doReschedule() }
                        >
                            Use My Warranty
                        </ActionButton>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={ bookingDetails !== null }
                onClose={ () => setBookingDetails(null) }
                fullscreen={ true }
            >
                <div className="akdbw-font-bold akdbw-text-xl akdbw-text-center">Booking Details</div>
                <div className="akdbw-mt-5 akdbw-border-b akdbw-border-border_gray">
                    { bookingDetails && bookingDetails?.id > 0 &&
                        <>
                            <Heading size={ 4 } className="akdbw-flex akdbw-justify-between akdbw-items-center">
                                Services
                            </Heading>
                            <div className="akdbw-py-3">
                                { bookingDetails?.booking_items.map(item => (
                                    <div className="akdbw-pb-2" key={ `b-${item.option_id}-${item.service_id}` }>
                                        { item.service }
                                        <br />
                                        <div className="akdbw-text-blue akdbw-text-sm akdbw--mt-1">{ item.option }</div>
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                </div>
                <div className="akdbw-mt-5 akdbw-border-b akdbw-border-border_gray akdbw-pb-3">
                    <Heading size={ 4 } className="akdbw-mb-3 akdbw-flex akdbw-justify-between akdbw-items-center">
                        Date & Time
                    </Heading>
                    <div className="akdbw-flex akdbw-flex-row akdbw-justify-between">
                        <div className="akdbw-font-medium">Date</div>
                        <div>{ displayDate(bookingDetails?.start || '') }</div>
                    </div>
                </div>
                <div className="akdbw-mt-5 akdbw-border-b akdbw-border-border_gray akdbw-pb-3">
                    <Heading size={ 4 } className="akdbw-mb-3 akdbw-flex akdbw-justify-between akdbw-items-center">
                        Location & Contact
                    </Heading>
                    <div className="akdbw-pt-3">{ bookingDetails?.location }</div>
                    <div className="akdbw-flex akdbw-flex-row akdbw-justify-between">
                        <div className="akdbw-font-medium">Phone</div>
                        <div>{ bookingDetails?.phone }</div>
                    </div>
                    <div className="akdbw-flex akdbw-flex-row akdbw-justify-between">
                        <div className="akdbw-font-medium">Email</div>
                        <div>{ bookingDetails?.email }</div>
                    </div>
                </div>
                <div className="akdbw-mt-5">
                    <Heading size={ 4 } className="akdbw-mb-3 akdbw-flex akdbw-justify-between akdbw-items-center">
                        Payment
                    </Heading>
                    { bookingDetails && bookingDetails.id > 0 &&
                        <>
                            <div className="akdbw-flex akdbw-flex-row akdbw-justify-between">
                                <div className="akdbw-font-medium">Payment type</div>
                                <div>{ bookingDetails?.payment }</div>
                            </div>
                        </>
                    }
                </div>
            </Modal>
        </>
                
    );
};
