import { useNavigate } from "react-router-dom";
import ActionButton from "./ActionButton";
import clsx from "clsx";
import { isMobileApp } from "./isHandymanMode";

type BottomButtonsProps = {
    nextPageUrl: string
    prevPageUrl?: string
    stepInvalid?: boolean
}

const mobileApp = isMobileApp()

const BottomButtons = ({ nextPageUrl, prevPageUrl, stepInvalid }: BottomButtonsProps) => {
    const navigate = useNavigate();

    const goToPage = (url: string) => {
        document.body.scrollTo(0, 0)
        navigate(url)
    }

    return (
        <div className={clsx(
						!mobileApp && 'akdbw-fixed akdbw-border-t akdbw-shadow-[rgba(0,0,15,0.5)_0px_-5px_10px_-6px]',
            "akdbw-flex akdbw-items-center akdbw-py-2 akdbw-px-10 md:akdbw-pt-10 md:akdbw-shadow-none akdbw-border-green md:akdbw-border-none md:akdbw-relative akdbw-bottom-0 akdbw-left-0 akdbw-w-full akdbw-bg-white",
						mobileApp && 'akdbw-pt-0 akdbw-pb-10',
            prevPageUrl && 'akdbw-justify-between',
            !prevPageUrl && 'akdbw-justify-end'

        )}>
            { prevPageUrl &&
                <ActionButton uppercase={ true } onClick={ () => goToPage(prevPageUrl) } inverse={ true }>Prev</ActionButton>
            }
            <ActionButton uppercase={ true } disabled={ stepInvalid } onClick={ () => goToPage(nextPageUrl) }>Next</ActionButton>
        </div>
    )
};

export default BottomButtons;
