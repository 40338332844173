import { useNavigate } from "react-router-dom";
import { AcceptedIcon } from "../icons/AcceptedIcon";
import ActionButton from "../shared/ActionButton";

export const OrderCreatedPage = () => {
    const navigate = useNavigate()

    return (
        <div className="akdbw-py-10 akdbw-mx-auto akdbw-max-w-[500px]">
            <div className="akdbw-text-2xl akdbw-font-bold akdbw-text-center">Booking received</div>
            <hr className="akdbw-my-4" />
            <AcceptedIcon className="akdbw-mx-auto akdbw-my-20 akdbw-text-green akdbw-scale-150" />
            <div className="akdbw-text-center akdbw-pt-5">
                <ActionButton
                    uppercase={ true }
                    onClick={ () => navigate('/profile/my_bookings') }
                >
                    My Bookings
                </ActionButton>
            </div>
        </div>
    );
}