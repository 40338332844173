import { FormEvent, useEffect, useState } from "react"
import { useCreateUpdateProfileCodeMutation, useDeleteProfileMutation, useGetProfileQuery, useUpdateProfileMutation } from "../store/bookings"
import { LoadingIndicator } from "../shared/LoadingIndicator"
import ActionButton from "../shared/ActionButton"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../hooks"
import { setPhone as setCartPhone, setEmail as setCartEmail } from '../store/cart'
import Modal from "../layout/Modal"
import { isMobileApp } from "../shared/isHandymanMode"

const mobileApp = isMobileApp()

const ProfilePage = () => {
    const { data, isLoading } = useGetProfileQuery(),
        [email, setEmail] = useState(''),
        [phone, setPhone] = useState(''),
        [password, setPassword] = useState(''),
        [passwordConfirmation, setPasswordConfirmation] = useState(''),
        [corporate, setIsCorporate] = useState(false),
        [code, setCode] = useState(''),
        [deleteAccount, setDeleteAccount] = useState(false),
        [initialPhone, setInitialPhone] = useState(''),
        [initialEmail, setInitialEmail] = useState(''),
        [updateCodeCreated, setUpdateCodeCreated] = useState(false),
        [createUpdateCode, createUpdateCodeData] = useCreateUpdateProfileCodeMutation(),
        [updateProfile, updateProfileData] = useUpdateProfileMutation(),
        [deleteProfile, deleteProfileData] = useDeleteProfileMutation(),
        [updateProfileError, setUpdateProfileError] = useState<string | null>(null),
        [done, setDone] = useState(false),
        navigate = useNavigate(),
        dispatch = useAppDispatch()

    useEffect(() => {
        if (data === undefined) {
            return
        }
        if (data.user === null) {
            navigate('/login')
        } else {
            setEmail(data.user.email)
            setPhone(data.user.phone)
            setIsCorporate(data.user.is_corporate)
            setInitialPhone(data.user.phone)
            setInitialEmail(data.user.email)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const getProfileCode = async (ev: FormEvent) => {
        ev.preventDefault()
        if (corporate) {
            if (password !== '' && password !== passwordConfirmation) {
                alert('Password and confirmation must match')
            } else {
                const result = await updateProfile({
                    phone,
                    email,
                    password,
                    code: 'coporate'
                }).unwrap()
                setDone(result.success)
                if (!result.success) {
                    setUpdateProfileError(result.message || '')
                } else {
                    setUpdateProfileError(null)
                    dispatch(setCartEmail(email))
                    dispatch(setCartPhone(phone))
                }
            }
        } else {
            setCode('')
            await createUpdateCode().unwrap()
            setUpdateCodeCreated(true)
        }
    }

    const saveProfile = async (ev: FormEvent) => {
        ev.preventDefault()
        const result = await updateProfile({
            phone,
            email,
            code
        }).unwrap()
        setDone(result.success)
        if (!result.success) {
            setUpdateProfileError(result.message || '')
        } else {
            setUpdateCodeCreated(false)
            setUpdateProfileError(null)
            dispatch(setCartEmail(email))
            dispatch(setCartPhone(phone))
        }
    }

    const doDeleteProfile = async () => {
      const result = await deleteProfile().unwrap()
      if (!result.success) {
          alert('Error has occured during profile deletion, please try later')
      } else {
          localStorage.clear()
          window.location.reload()
      }
    }

    if (isLoading || createUpdateCodeData.isLoading || updateProfileData.isLoading || deleteProfileData.isLoading) {
        return <LoadingIndicator />
    }

    return (
        <div>
            { !mobileApp &&
                <div className="akdbw-text-4xl akdbw-font-bold">My profile</div>
            }
            { updateProfileError &&
                <div className="akdbw-py-2 akdbw-px-4 akdbw-bg-red-400 akdbw-text-white akdbw-border akdbw-border-red akdbw-rounded-md akdbw-my-3">
                    { updateProfileError }
                </div>
            }
            { done &&
                <div className="akdbw-py-2 akdbw-px-4 akdbw-bg-light_green akdbw-border akdbw-border-green akdbw-rounded-md akdbw-my-3">
                    Your profile has been updated
                </div>
            }
            { updateCodeCreated ? (
                <form onSubmit={ saveProfile }>
                    <div className="akdbw-py-3">We sent confirmation code to { initialPhone }. Please input it in the field below</div>
                    <div className="akdbw-mb-4">
                        <input
                            className="akdbw-px-4 akdbw-py-2 akdbw-w-full akdbw-border akdbw-border-border_gray"
                            type="text"
                            value={ code }
                            required
                            onChange={ ev => setCode(ev.target.value) }
                        />
                    </div>
                    <ActionButton
                        onClick={ () => {} }
                        submit={ true }
                    >
                        Confirm change
                    </ActionButton>
                    <ActionButton
                        className="akdbw-ml-4"
                        onClick={ () => {
                            setUpdateCodeCreated(false)
                            setDone(false)
                            setUpdateProfileError(null)
                        } }
                        submit={ false }
                        inverse={ true }
                    >
                        Back
                    </ActionButton>
                </form>
            ) : (
                <>
                    <form onSubmit={ getProfileCode } className="akdbw-mt-10 akdbw-w-full akdbw-max-w-[500px]">
                        <div className="akdbw-mb-4">
                            <label className="akdbw-font-bold akdbw-block akdbw-mb-2">Phone number</label>
                            <input
                                className="akdbw-px-4 akdbw-py-2 akdbw-w-full akdbw-border akdbw-border-border_gray"
                                type="text"
                                value={ phone }
                                required
                                onChange={ ev => setPhone(ev.target.value) }
                            />
                        </div>
                        <div className="akdbw-mb-4">
                            <label className="akdbw-font-bold akdbw-block akdbw-mb-2">Email</label>
                            <input
                                className="akdbw-px-4 akdbw-py-2 akdbw-w-full akdbw-border akdbw-border-border_gray"
                                type="email"
                                value={ email }
                                required
                                onChange={ ev => setEmail(ev.target.value) }
                            />
                        </div>
                        { corporate ?
                            <>
                                <div className="akdbw-mb-4">
                                    <label className="akdbw-font-bold akdbw-block akdbw-mb-2">Password</label>
                                    <input
                                        className="akdbw-px-4 akdbw-py-2 akdbw-w-full akdbw-border akdbw-border-border_gray"
                                        type="password"
                                        value={ password }
                                        onChange={ ev => setPassword(ev.target.value) }
                                    />
                                </div>
                                <div className="akdbw-mb-4">
                                    <label className="akdbw-font-bold akdbw-block akdbw-mb-2">Password confirmation</label>
                                    <input
                                        className="akdbw-px-4 akdbw-py-2 akdbw-w-full akdbw-border akdbw-border-border_gray"
                                        type="password"
                                        value={ passwordConfirmation }
                                        onChange={ ev => setPasswordConfirmation(ev.target.value) }
                                    />
                                </div>
                            </> : null
                        }
                        <ActionButton
                            onClick={ () => {} }
                            submit={ true }
                            // disabled={ phone === initialPhone && email === initialEmail }
                            className="akdbw-w-full"
                        >
                            Save
                        </ActionButton>
                    </form>
                    { mobileApp &&
                        <>
                            <br/><br/><br/><br/>
                            <ActionButton
                                onClick={ () => setDeleteAccount(true) }
                                danger={ true }
                                className="akdbw-w-full"
                            >
                                Delete your account
                            </ActionButton>
                        </>
                    }
                </>
            ) }
            { mobileApp &&
              <Modal
                  visible={ deleteAccount }
                  onClose={ () => setDeleteAccount(false) }
                  fullscreen={ true }
              >
                  <div>
                      <div className="akdbw-font-bold akdbw-text-xl akdbw-text-center akdbw-mb-10">Delete your account</div>
                      <p className="akdbw-mb-4">Account deletion will result in removal of all records related to your account from our system.</p>
                      <p className="akdbw-mb-4">If you have active bookings for the future, we'll remove them once they're done</p>
                      <p>Do you want to proceed with account deletion?</p>
                      <div className="akdbw-flex akdbw-items-center akdbw-p-2 md:akdbw-pt-10 akdbw-shadow-[rgba(0,0,15,0.5)_0px_-5px_10px_-6px] md:akdbw-shadow-none akdbw-border-green akdbw-border-t md:akdbw-border-none akdbw-fixed md:akdbw-relative akdbw-bottom-0 akdbw-left-0 akdbw-w-full akdbw-bg-white akdbw-justify-between">
                          <ActionButton
                              uppercase={ true }
                              onClick={ () => setDeleteAccount(false) }
                              inverse={ true }
                          >
                              Cancel
                          </ActionButton>
                          <ActionButton
                              uppercase={ true }
                              danger={ true }
                              onClick={ doDeleteProfile }
                          >
                              Delete account
                          </ActionButton>
                      </div>
                  </div>
              </Modal>
            }
        </div>
    )
}

export default ProfilePage
