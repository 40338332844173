import { IconProps } from "./IconProps";

export const MessagesIcon = ({ className }: IconProps) => (
	<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg" className={ className }>
		<g clipPath="url(#clip0_0_22)">
			<path d="M6.5875 5.991H8.40328C8.69917 3.32082 10.9701 1.23767 13.7175 1.23767H24.4125C27.3608 1.23767 29.76 3.63692 29.76 6.58517V23.0483C29.76 23.772 29.3643 24.4339 28.725 24.775C28.435 24.9307 28.1177 25.0067 27.8016 25.0067C27.4226 25.0067 27.0435 24.8962 26.7155 24.6775L22.5278 21.8862C22.0418 24.3377 19.8743 26.1915 17.2825 26.1915H9.14361L4.28451 29.4309C3.95653 29.6507 3.57864 29.76 3.19838 29.76C2.88228 29.76 2.56499 29.684 2.27504 29.5283C1.63691 29.1873 1.24 28.5254 1.24 27.8017V11.3385C1.24 8.39025 3.63925 5.991 6.5875 5.991ZM27.375 23.69C27.6162 23.8505 27.9098 23.8659 28.1653 23.7281C28.4196 23.5926 28.5717 23.3383 28.5717 23.0495V6.58517C28.5717 4.29169 26.706 2.426 24.4125 2.426H13.7175C11.424 2.426 9.55834 4.29169 9.55834 6.58517V16.0918C9.55834 18.3853 11.424 20.251 13.7175 20.251H21.961C22.144 20.251 22.2996 20.3152 22.3709 20.3544L27.375 23.69ZM2.42834 27.8029C2.42834 28.0916 2.58044 28.3459 2.83475 28.4814C3.08905 28.6181 3.38494 28.6026 3.62499 28.4434L8.63381 25.1042C8.73126 25.0388 8.84652 25.0043 8.96298 25.0043H17.2813C19.3728 25.0043 21.1089 23.4524 21.3977 21.4393H13.7163C10.7681 21.4393 8.36881 19.0401 8.36881 16.0918V7.17934H6.58632C4.29283 7.17934 2.42715 9.04502 2.42715 11.3385V27.8017L2.42834 27.8029Z" fill="white" stroke="white"/>
		</g>
		<defs>
			<clipPath id="clip0_0_22">
				<rect width="31" height="31" fill="white" transform="matrix(-1 0 0 1 31 0)"/>
			</clipPath>
		</defs>
	</svg>
)