import { useAutocomplete } from "@vis.gl/react-google-maps";
import { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectLocationName, setLocation, setLocationName } from "../store/cart";

const PlacesAutocomplete = () => {
    const inputRef = useRef(null),
        locationName = useAppSelector(selectLocationName),
        dispatch = useAppDispatch()

    const onPlaceChanged = (place: google.maps.places.PlaceResult) => {
        if (place) {
            const parts = [];
            if (place.name) {
                parts.push(place.name)
            }
            if (place.formatted_address) {
                parts.push(place.formatted_address)
            }
            dispatch(setLocationName(parts.join(' - ')))
            if (place.geometry?.location) {
                dispatch(setLocation({
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                }))
            }
        }
        inputRef.current && (inputRef.current as HTMLInputElement).focus()
    }

    useAutocomplete({
        inputField: inputRef && inputRef.current,
        onPlaceChanged
    })

    return (
        <input
            className="akdbw-w-full akdbw-border akdbw-border-border_gray akdbw-py-2 akdbw-px-4"
            ref={inputRef}
            value={locationName}
            placeholder="Location search..."
            onChange={ ev => dispatch(setLocationName(ev.target.value)) }
        />
    )
}

export default PlacesAutocomplete