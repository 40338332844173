import Cart from "../checkout/Cart"
import { useAppSelector } from "../hooks";
import CheckoutProgress from "../shared/CheckoutProgress"
import { selectCartTotal } from "../store/cart";
import BottomButtons from "../shared/BottomButtons";
import CheckoutLayout from "../layout/CheckoutLayout";
import { isMobileApp } from "../shared/isHandymanMode";

const mobileApp = isMobileApp()

const ServiceDetailsPage = () => {
    const cartTotal = useAppSelector(selectCartTotal),
        vat = cartTotal * 0.05

    return (
        <div>
            <CheckoutProgress step={ 1 } />
            <CheckoutLayout>
                <Cart displayPrice={ true }/>
                <table className="akdbw-mt-10 akdbw-bg-pale_green akdbw-rounded-md akdbw-w-full">
                    <tbody>
                        <tr>
                            <td className="akdbw-px-4 akdbw-py-2">Services:</td>
                            <td className="akdbw-px-4 akdbw-py-2 akdbw-text-right">{ cartTotal } AED</td>
                        </tr>
                        <tr>
                            <td className="akdbw-px-4 akdbw-py-2">VAT (5%):</td>
                            <td className="akdbw-px-4 akdbw-py-2 akdbw-text-right">{ vat.toFixed(2) } AED</td>
                        </tr>
                        <tr>
                            <td className="akdbw-px-4 akdbw-py-2 akdbw-font-bold">Total:</td>
                            <td className="akdbw-px-4 akdbw-py-2 akdbw-font-bold akdbw-text-right">{ (cartTotal + vat).toFixed(2) } AED</td>
                        </tr>
                    </tbody>
                </table>
            </CheckoutLayout>
            <BottomButtons nextPageUrl={ mobileApp ? '/profile/date_time' : '/date_time' } prevPageUrl="/" />
        </div>
    )
}

export default ServiceDetailsPage