// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ROOT } from '../config';

export type ServiceOption = {
    id: number
    name: string
    warranty_months: number
    price: number
    service_time: number
    old_price: number
    booking_count: number
    description: string
};

export type Service = {
    id: number
    name: string
    img: string
    p_ants: boolean
    p_flying: boolean
    p_bed_bugs: boolean
    p_cockroches: boolean
    p_mice: boolean
    p_ticks: boolean
    p_crickets: boolean
    p_rats: boolean
    p_fleas: boolean
    p_silverfish: boolean
    service_details: string
    rating: number
    indoor: number
    video_code: string
    one_time: number
    options: ServiceOption[]
};

export const servicesApi = createApi({
    reducerPath: 'servicesApi',
    baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
    endpoints: (builder) => ({
        getServices: builder.query<Service[], void>({
            query: () => 'services'
        }),
        getHandymanServices: builder.query<Service[], void>({
            query: () => 'handyman_services'
        })
    })
});

export const { useGetServicesQuery, useGetHandymanServicesQuery } = servicesApi;