import { Booking } from "../store/bookings"

export const getBookingStatus = (booking: Booking) => {
    if (booking.id < 0) {
        if (booking.event?.job_status === 'done') {
            return 4;
        }
        if (booking.event?.job_status === 'new') {
            return 3;
        }
        if (booking.event?.job_status === 'cancel') {
            return -1;
        }
    }
    let result = 2
    if (booking.event_id) {
        result = 3
    }
    if (booking.event && booking.event.job_status === 'done') {
        result = 4
    }
    return result
}