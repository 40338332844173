import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../hooks";
import CheckoutLayout from "../layout/CheckoutLayout";
import BottomButtons from "../shared/BottomButtons";
import CheckoutProgress from "../shared/CheckoutProgress";
import Heading from "../shared/Heading";
import { isMobileApp } from "../shared/isHandymanMode";
import { selectPaymentType, setPaymentType } from "../store/cart";

const mobileApp = isMobileApp()

const PaymentPage = () => {
    const dispatch = useAppDispatch(),
        paymentType = useAppSelector(selectPaymentType)

    return (
        <div>
            <CheckoutProgress step={ 4 } />
            <CheckoutLayout>
                <Heading size={3}>How Would You Like To Pay For Your Service?</Heading>
                <div className="akdbw-py-3">You pay only after the service is completed</div>
                <div
                    className={clsx(
                        "akdbw-text-lg akdbw-p-4 akdbw-border akdbw-border-green akdbw-rounded-md akdbw-shadow-md hover:akdbw-bg-green hover:akdbw-text-white akdbw-cursor-pointer",
                        (paymentType === 'card') && 'akdbw-bg-green akdbw-text-white'
                    )}
                    onClick={ () => dispatch(setPaymentType('card')) }
                >
                    Credit card
                </div>
                <div
                    className={clsx(
                        "akdbw-text-lg akdbw-p-4 akdbw-border akdbw-border-green akdbw-rounded-md akdbw-shadow-md hover:akdbw-bg-green hover:akdbw-text-white akdbw-cursor-pointer akdbw-mt-5",
                        (paymentType === 'cash') && 'akdbw-bg-green akdbw-text-white'
                    )}
                    onClick={ () => dispatch(setPaymentType('cash')) }
                >
                    Cash on delivery
                </div>
            </CheckoutLayout>
            <BottomButtons
                nextPageUrl={ mobileApp ? '/profile/summary' : '/summary' }
                prevPageUrl={ mobileApp ? '/profile/location' : '/location' }
                stepInvalid={ paymentType === '' }
            />
        </div>
    )
};

export default PaymentPage;