import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { selectCartItems, selectCartTotal, selectEmail, selectLocationName, selectPaymentType, selectPhone, selectServiceDate, selectServiceTime } from "../store/cart";
import Heading from "./Heading";
import { isMobileApp } from "./isHandymanMode";

type SummaryProps = {
    title?: string
    displayEditLinks?: boolean
}

type EditLinkProps = {
    url: string
}

const EditLink = ({ url }: EditLinkProps) => {
    const navigate = useNavigate()

    return (
        <div className="text-xs text-blue underline hover:no-underline cursor-pointer" onClick={ () => navigate(url) }>Edit</div>
    )
}

const mobileApp = isMobileApp()

const Summary = ({ title, displayEditLinks }: SummaryProps) => {
    const cartItems = useAppSelector(selectCartItems),
        serviceDate = useAppSelector(selectServiceDate),
        serviceTime = useAppSelector(selectServiceTime),
        locationName = useAppSelector(selectLocationName),
        phone = useAppSelector(selectPhone),
        email = useAppSelector(selectEmail),
        paymentType = useAppSelector(selectPaymentType),
        cartTotal = useAppSelector(selectCartTotal),
        vat = cartTotal * 0.05

    return (
        <div className="akdbw-shadow-md akdbw-rounded-md akdbw-p-4">
            <Heading size={ 3 } className="akdbw-border-b akdbw-border-border_gray">
                { title ? title : 'Summary' }
            </Heading>
            {/* <div className="akdbw-mt-5 akdbw-border-b akdbw-border-border_gray">
                <Heading size={ 4 } className="akdbw-flex akdbw-justify-between akdbw-items-center">
                    Services
                    { displayEditLinks && <EditLink url="/"/> }
                </Heading>
                <div className="akdbw-py-3">
                    { cartItems.map(cartItem => (
                        <div className="akdbw-pb-2" key={ cartItem.option.id }>
                            { cartItem.service.name }
                            <br />
                            <div className="akdbw-text-blue akdbw-text-sm akdbw--mt-1">{ cartItem.option.name }</div>
                        </div>
                    ))}
                </div>
            </div> */}
            { (serviceDate !== '' || serviceTime !== '') &&
                <div className="akdbw-mt-5 akdbw-border-b akdbw-border-border_gray akdbw-pb-3">
                    <Heading size={ 4 } className="akdbw-mb-3 akdbw-flex akdbw-justify-between akdbw-items-center">
                        Date & Time
                        { displayEditLinks && <EditLink url={ mobileApp ? '/profile/date_time' : '/date_time'}/> }
                    </Heading>
                    { serviceDate !== '' &&
                        <div className="akdbw-flex akdbw-flex-row akdbw-justify-between">
                            <div className="akdbw-font-medium">Date</div>
                            <div>{ serviceDate }</div>
                        </div>
                    }
                    { serviceTime !== '' &&
                        <div className="akdbw-flex akdbw-flex-row akdbw-justify-between">
                            <div className="akdbw-font-medium">Time range</div>
                            <div>{ serviceTime }</div>
                        </div>
                    }
                </div>
            }
            { (locationName && locationName !== '') &&
                <div className="akdbw-mt-5 akdbw-border-b akdbw-border-border_gray akdbw-pb-3">
                    <Heading size={ 4 } className="akdbw-mb-3 akdbw-flex akdbw-justify-between akdbw-items-center">
                        Location & Contact
                        { displayEditLinks && <EditLink url={ mobileApp ? '/profile/location' : '/location' }/> }
                    </Heading>
                    <div className="akdbw-pt-3">{ locationName }</div>
                    { phone !== '' &&
                        <div className="akdbw-flex akdbw-flex-row akdbw-justify-between">
                            <div className="akdbw-font-medium">Phone</div>
                            <div>{ phone }</div>
                        </div>
                    }
                    { email !== '' &&
                        <div className="akdbw-flex akdbw-flex-row akdbw-justify-between">
                            <div className="akdbw-font-medium">Email</div>
                            <div>{ email }</div>
                        </div>
                    }
                </div>
            }
            <div className="akdbw-mt-5">
                <Heading size={ 4 } className="akdbw-mb-3 akdbw-flex akdbw-justify-between akdbw-items-center">
                    Payment
                    { displayEditLinks && <EditLink url={ mobileApp ? '/profile/payment' : '/payment' }/> }
                </Heading>
                { paymentType !== '' &&
                    <div className="akdbw-flex akdbw-flex-row akdbw-justify-between">
                        <div className="akdbw-font-medium">Payment type</div>
                        <div>{ paymentType }</div>
                    </div>
                }
                {/* <div className="akdbw-flex akdbw-flex-row akdbw-justify-between">
                    <div className="akdbw-font-medium">Service charge</div>
                    <div>{ cartTotal } AED</div>
                </div>
                <div className="akdbw-flex akdbw-flex-row akdbw-justify-between">
                    <div className="akdbw-font-medium">VAT (5%)</div>
                    <div>{ vat.toFixed(2) } AED</div>
                </div>
                <div className="akdbw-flex akdbw-flex-row akdbw-justify-between akdbw-text-lg akdbw-border-t akdbw-border-border_gray akdbw-mt-2 akdbw-pt-2">
                    <div className="akdbw-font-medium">Total</div>
                    <div>{ (cartTotal + vat).toFixed(2) } AED</div>
                </div> */}
            </div>
        </div>
    )
};

export default Summary;