import clsx from "clsx";
import { isMobileApp } from "../shared/isHandymanMode";
import Summary from "../shared/Summary"

type CheckoutLayoutProps = {
    children: React.ReactNode
}

const mobileApp = isMobileApp()

const CheckoutLayout = ({ children }: CheckoutLayoutProps) => (
    <div className={ clsx(
				"akdbw-grid akdbw-grid-cols-1 md:akdbw-grid-cols-3 akdbw-pt-5 akdbw-gap-5 akdbw-pb-20 md:akdbw-pb-5",
				mobileApp && 'akdbw-pb-5'
		)}>
        <div className="akdbw-col-span-2 md:akdbw-col-span-3">
            <div className="akdbw-shadow-md akdbw-rounded-md akdbw-p-5">
                { children }
            </div>
        </div>
        {/* <Summary /> */}
    </div>
);

export default CheckoutLayout;
