import { IconProps } from "./IconProps";

export const HomeIconActive = ({ className }: IconProps) => (
	<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={ className }>
		<g clipPath="url(#clip0_0_6)">
			<mask id="mask0_0_6" style={ { maskType:'luminance' } } maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
				<path d="M0 0L0 30L30 30L30 0L0 0Z" fill="white"/>
			</mask>
			<g mask="url(#mask0_0_6)">
				<path fillRule="evenodd" clipRule="evenodd" d="M11.9137 2.70417C13.6809 1.15834 16.3191 1.15834 18.0862 2.70419L26.4092 9.98483C27.6981 11.1123 28.4375 12.7414 28.4375 14.4537V25C28.4375 27.5889 26.3389 29.6875 23.75 29.6875H6.25C3.66116 29.6875 1.5625 27.5889 1.5625 25V14.4537C1.5625 12.7414 2.30185 11.1123 3.59074 9.98483L11.9137 2.70417Z" fill="white"/>
				<g filter="url(#filter0_b_0_6)">
					<path fillRule="evenodd" clipRule="evenodd" d="M15 24.6875C14.4822 24.6875 14.0625 24.2677 14.0625 23.75V20C14.0625 19.4822 14.4822 19.0625 15 19.0625C15.5178 19.0625 15.9375 19.4822 15.9375 20V23.75C15.9375 24.2677 15.5178 24.6875 15 24.6875Z" fill="white" fillOpacity="0.15"/>
				</g>
			</g>
		</g>
		<defs>
			<filter id="filter0_b_0_6" x="-10.9375" y="-5.9375" width="51.875" height="55.625" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
				<feFlood floodOpacity="0" result="BackgroundImageFix"/>
				<feGaussianBlur in="BackgroundImageFix" stdDeviation="12.5"/>
				<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_0_6"/>
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_0_6" result="shape"/>
			</filter>
			<clipPath id="clip0_0_6">
				<rect width="30" height="30" fill="white"/>
			</clipPath>
		</defs>
	</svg>
)
