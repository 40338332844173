import { useEffect, useRef } from "react"
import { useInView } from "react-intersection-observer"

type YoutubeEmbedProps = {
    url: string
}

const YoutubeEmbed = ({ url }: YoutubeEmbedProps) => {
    const videoRef = useRef(null),
        { ref, inView } = useInView({
            threshold: 0
        })

    useEffect(() => {
        if (!videoRef.current) {
            return
        }
        if (inView) {
            (videoRef.current as HTMLVideoElement).play()
        } else {
            (videoRef.current as HTMLVideoElement).pause()
        }
    }, [inView])

    return (
        <div ref={ref} className="akdbw-relative akdbw-pb-[56.25%] akdbw-h-[0]">
            <video
                ref={ videoRef }
                muted
                loop
                className="akdbw-absolute akdbw-top-0 akdbw-left-0 akdbw-h-full akdbw-w-full"
                playsInline
            >
                <source src={ url } type='video/mp4' />
            </video>
        </div>
    );
}
  
  
export default YoutubeEmbed;