import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "../hooks";
import { CartItem, decreaseInCart, increaseInCart, selectCartItems, selectHandymanCartItems } from "../store/cart";
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { getSavedHandymanState } from "../shared/isHandymanMode";

type CartProps = {
    displayPrice: boolean
    handymanMode?: boolean
}

const Cart = ({ displayPrice, handymanMode }: CartProps) => {
    const pestcontrolCartItems = useAppSelector(selectCartItems),
        handymanCartItems = useAppSelector(selectHandymanCartItems),
        dispatch = useAppDispatch(),
        ht = handymanMode === undefined ? getSavedHandymanState() : handymanMode,
        cartItems = ht ? handymanCartItems : pestcontrolCartItems

    const increaseCartCount = (item: CartItem) => {
        dispatch(
            increaseInCart({ option: item.option, handyman: ht })
        )
    }

    const decreaseCartCount = (item: CartItem) => {
        dispatch(
            decreaseInCart({ option: item.option, handyman: ht })
        )
    }

    return (
        <table className="akdbw-w-full">
            <thead>
                <tr>
                    <td className="akdbw-border-b akdbw-border-dark_gray akdbw-py-3">Service</td>
                </tr>
            </thead>
            <tbody>
                { cartItems.map(cartItem => (
                    <tr key={ cartItem.option.id }>
                        <td className="akdbw-border-b akdbw-border-border_gray akdbw-py-2">
                            { cartItem.service.name }
                            <br />
                            <span className="akdbw-text-blue akdbw-text-sm">{ cartItem.option.name }</span>
                            { displayPrice &&
                                <>
                                    <br/>
                                    <span className="akdbw-font-bold">{ cartItem.option.price * cartItem.count } AED</span>
                                </>
                            }
                        </td>
                    </tr>
                )) }
            </tbody>
        </table>
    );
};

export default Cart;