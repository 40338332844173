import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import CheckoutLayout from "../layout/CheckoutLayout";
import BottomButtons from "../shared/BottomButtons";
import CheckoutProgress from "../shared/CheckoutProgress";
import Heading from "../shared/Heading";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectEmail, selectLocation, selectLocationName, selectPhone, setEmail, setLocation, setLocationName, setPhone } from "../store/cart";
import PlacesAutocomplete from "../shared/PlacesAutocomplete";
import { useGetProfileQuery } from "../store/bookings";
import { useEffect } from "react";
import { isMobileApp } from "../shared/isHandymanMode";

const API_KEY = 'AIzaSyAPLWXYkp2625RATSkcHqTiAPJrI7JrvTw';

const mobileApp = isMobileApp()

const LocationPage = () => {
    const dispatch = useAppDispatch(),
        location = useAppSelector(selectLocation),
        locationName = useAppSelector(selectLocationName),
        phone = useAppSelector(selectPhone),
        email = useAppSelector(selectEmail),
        { data, isLoading } = useGetProfileQuery()

    useEffect(() => {
        if (data === undefined) {
            return
        }
        if (data.user !== null) {
            dispatch( setPhone(data.user.phone) )
            dispatch( setEmail(data.user.email) )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const saveLocation = (ev: google.maps.MapMouseEvent) => {
        const lat = ev.latLng?.lat(),
            lng = ev.latLng?.lng();
        if (lat === undefined || lng === undefined) {
            return
        }
        dispatch(
            setLocation({ lat, lng })
        )
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({
            location: ev.latLng
        }).then((response: google.maps.GeocoderResponse) => {
            if (response.results.length > 0) {
                const addressComponents = response.results[0].address_components;
                const countryComponent = addressComponents.find((component) =>
                  component.types.includes('country')
                );

                let countryIsCorrect = true;
                if (countryComponent) {
                  const countryName = countryComponent.long_name;
                  const countryCode = countryComponent.short_name;
                  console.log(`Country: ${countryName} (${countryCode})`);
                  // Use the detected country (e.g., UAE) here
                  countryIsCorrect = (countryCode === 'AE');
                }

                if (countryIsCorrect) {
                    dispatch(
                        setLocationName(response.results[0].formatted_address)
                    )
                } else {
                    dispatch( setLocationName('') )
                    alert('Please select an address within UAE')
                }
            }
        })
    }

    return (
        <div>
            <CheckoutProgress step={ 3 } />
            <CheckoutLayout>
                <Heading size={3}>Where Do You Need The Service?</Heading>
                <APIProvider apiKey={ API_KEY } libraries={['places']}>
                    <div className="akdbw-h-[300px] akdbw-mt-5">
                        <Map
                            zoom={ 10 }
                            center={ { lat: location.lat, lng: location.lng } }
                        >
                            <Marker
                                position={ { lat: location.lat, lng: location.lng } }
                                draggable={ true }
                                onDragEnd={ saveLocation }
                            />
                        </Map>
                    </div>
                    <div className="akdbw-py-3">
                        <PlacesAutocomplete />
                    </div>
                </APIProvider>
                <div className="akdbw-grid akdbw-grid-cols-1 md:akdbw-grid-cols-2 akdbw-gap-5">
                    <div className="akdbw-flex akdbw-flex-row akdbw-items-center">
                        <label className="akdbw-w-1/2 akdbw-font-bold">Phone number:</label>
                        <input
                            type="text"
                            className="akdbw-w-full akdbw-border akdbw-border-border_gray akdbw-py-2 akdbw-px-4"
                            name="phone"
                            value={ phone }
                            onChange={ ev => dispatch( setPhone(ev.target.value) ) }
                        />
                    </div>
                    <div className="akdbw-flex akdbw-flex-row akdbw-items-center">
                        <label className="akdbw-w-1/2 akdbw-font-bold">Email:</label>
                        <input
                            type="email"
                            name="email"
                            className="akdbw-w-full akdbw-border akdbw-border-border_gray akdbw-py-2 akdbw-px-4"
                            value={ email }
                            onChange={ ev => dispatch( setEmail(ev.target.value) ) }
                        />
                    </div>
                </div>
            </CheckoutLayout>
            <BottomButtons
                nextPageUrl={ mobileApp ? '/profile/payment' : '/payment' }
                prevPageUrl={ mobileApp ? '/profile/date_time' : '/date_time' }
                stepInvalid={ email === '' || phone === '' || locationName === '' }
            />
        </div>
    )
};

export default LocationPage;