import { useGetHandymanServicesQuery, useGetServicesQuery } from "../store/services";
import ServiceInfo from "./ServiceInfo";

type ServiceListProps = {
    handymanMode: boolean
    categoryId: number
}

const ServiceList = ({ handymanMode, categoryId }: ServiceListProps) => {
    const { data: pestcontrolData } = useGetServicesQuery(),
        { data: handymanData } = useGetHandymanServicesQuery(),
        data = handymanMode ? handymanData : pestcontrolData

    if (!data) {
        return null;
    }

    return (
        <>
            { data.filter(x => x.id === categoryId).map(service => (
                <ServiceInfo key={ service.id } service={ service } handyman={ handymanMode } />
            )) }
        </>
    );
};

export default ServiceList;