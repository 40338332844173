import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faRightFromBracket, faUser, faContactBook, faHome } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from "react-router-dom";
import { clearLoginToken } from "../utils/tokenStorage";
import { AkkadLogo } from "./Logo";
import clsx from "clsx";
import { isMobileApp } from "./isHandymanMode";

const ITEM_CLASS = 'akdbw-py-2 akdbw-px-2 akdbw-border-b akdbw-border-green akdbw-cursor-pointer hover:akdbw-bg-light_green'
const mobileApp = isMobileApp()
const LOGO_CLS = mobileApp ? 'akdbw-mb-5' : 'akdbw-hidden'

type LoggedInSidebarProps = {
    onClickCallback?: () => void
}

const LoggedInSidebar = ({ onClickCallback }: LoggedInSidebarProps) => {
    const navigate = useNavigate()

    const logout = () => {
        if (window.confirm('Are you sure?')) {
            clearLoginToken()
            navigate('/login')
        }
    }

    const navigateTo = (url: string) => {
        navigate(url)
        if (onClickCallback) {
            onClickCallback();
        }
    }

    return (
        <>
            <img
                src={ AkkadLogo }
                className={ clsx(
                    "akdbw-w-1/2 akdbw-mx-auto md:akdbw-block",
                    LOGO_CLS
                )}
                alt=""
            />
            <ul className="md:akdbw-mt-20">
                { mobileApp &&
                    <li
                        onClick={ () => navigateTo('/profile/home') }
                        className={ ITEM_CLASS }
                    >
                        <FontAwesomeIcon
                            icon={ faHome }
                            className="akdbw-mr-2 akdbw-text-green"
                        />
                        Home
                    </li>
                }
                <li
                    onClick={ () => navigateTo('/profile/my_bookings') }
                    className={ ITEM_CLASS }
                >
                    <FontAwesomeIcon
                        icon={ faCalendar }
                        className="akdbw-mr-2 akdbw-text-green"
                    />
                    My Bookings
                </li>
                <li
                    onClick={ () => navigateTo('/profile/edit') }
                    className={ ITEM_CLASS }
                >
                    <FontAwesomeIcon
                        icon={ faUser }
                        className="akdbw-mr-2 akdbw-text-green"
                    />
                    Profile
                </li>
                <li
                    onClick={ () => window.location.href = 'https://www.pestcontrol.ae/contact-us/' }
                    className={ ITEM_CLASS }
                >
                    <FontAwesomeIcon
                        icon={ faContactBook }
                        className="akdbw-mr-2 akdbw-text-green"
                    />
                    Contact Us
                </li>
                {/* <li
                    onClick={ () => window.open('https://wa.me/+9710504001344') }
                    className={ ITEM_CLASS }
                >
                    <FontAwesomeIcon
                        icon={ faWhatsapp }
                        className="akdbw-mr-2 akdbw-text-green"
                    />
                    Request reschedule
                </li> */}
                <li
                    onClick={ logout }
                    className="akdbw-py-2 akdbw-px-2 akdbw-cursor-pointer hover:akdbw-bg-light_green"
                >
                    <FontAwesomeIcon
                        icon={ faRightFromBracket }
                        className="akdbw-mr-2 akdbw-text-green"
                    />
                    Logout
                </li>
            </ul>
        </>
    )
};

export default LoggedInSidebar;
