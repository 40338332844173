import { useNavigate } from "react-router-dom";
import { isMobileApp } from "../shared/isHandymanMode";
import { Service, useGetHandymanServicesQuery, useGetServicesQuery } from "../store/services";

type ServiceSelectorProps = {
    handymanMode: boolean
}

const ServiceSelector = ({ handymanMode }: ServiceSelectorProps) => {
    const { data: pestcontrolData } = useGetServicesQuery(),
        { data: handymanData } = useGetHandymanServicesQuery(),
        data = handymanMode ? handymanData : pestcontrolData,
        navigate = useNavigate()

    if (!data) {
        return null;
    }

    return (
        <div className="akdbw-px-3 md:akdbw-px-0">
            <div className="akdbw-grid akdbw-grid-cols-3 md:akdbw-grid-cols-2 xl:akdbw-grid-cols-3 akdbw-gap-4 md:akdbw-gap6">
                { data.map(service => (
                    <div
                        key={service.id}
                        className="akdbw-mb-5 akdbw-border-green akdbw-rounded-lg md:akdbw-border-0 akdbw-cursor-pointer akdbw-text-center"
                        onClick={ () => navigate(isMobileApp() ? `/profile/select_service_type/${service.id}` : `/select_service_type/${service.id}`) }
                    >
                        <img src={ service.img } alt={ service.name } className="akdbw-mx-auto" />
                        <div className="akdbw-font-bold akdbw-px-1 md:akdbw-px-3 akdbw-py-2 akdbw-text-center akdbw-text-sm md:akdbw-text-lg">{ service.name }</div>
                    </div>
                )) }
            </div>
        </div>
    );
};

export default ServiceSelector;