import { FormEvent, useState } from "react";
import ActionButton from "../shared/ActionButton";
import CheckoutProgress from "../shared/CheckoutProgress";
import Summary from "../shared/Summary";
import { useCreateBookingMutation, useCreateHandymanBookingMutation, useCreateLoginCodeMutation, useCreateTokenMutation } from "../store/bookings";
import { useAppDispatch, useAppSelector } from "../hooks";
import { clearCart, selectEmail, selectPhone, selectWholeCart } from "../store/cart";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { saveLoginToken } from "../utils/tokenStorage";
import { useNavigate } from "react-router-dom";
import { getSavedHandymanState, isMobileApp } from "../shared/isHandymanMode";

const mobileApp = isMobileApp(),
		handymanApi = getSavedHandymanState() 

const SummaryPage = () => {
    const [bookingError, setBookingError] = useState(false),
        [createPestcontrolBookingApi, pestcontrolBookingData] = useCreateBookingMutation(),
				[createHandymanBookingApi, handymanBookingData] = useCreateHandymanBookingMutation(),
				createBookingApi = handymanApi ? createHandymanBookingApi : createPestcontrolBookingApi,
				bookingData = handymanApi ? handymanBookingData : pestcontrolBookingData,
        cartData = useAppSelector(selectWholeCart),
        navigate = useNavigate(),
        dispatch = useAppDispatch()

    const createBooking = async (ev: FormEvent) => {
        ev.preventDefault()
        const bookingData = await createBookingApi(cartData).unwrap()
        setBookingError(!bookingData.success)
        if (bookingData.success) {
            dispatch(clearCart())
            document.body.scrollTo(0, 0)
            navigate(mobileApp ? '/profile/order_created' : '/order_created')
        } else {
            alert('Error creating order')
        }
    }

    if (bookingData.isLoading) {
        return <LoadingIndicator/>
    }

    return (
        <div>
            <CheckoutProgress step={ 5 } />
            <div className="akdbw-max-w-[800px] akdbw-mx-auto akdbw-pt-5 akdbw-bg-white akdbw-mt-10">
                { bookingError &&
                    <div className="akdbw-py-2 akdbw-px-4 akdbw-bg-red-400 akdbw-text-white akdbw-border akdbw-border-red akdbw-rounded-md akdbw-mt-3">
                        Error creating a booking
                    </div>
                }
                <Summary
                    title="Please review your booking details and confirm your booking"
                    displayEditLinks={ true }
                />
            </div>
            <form onSubmit={ createBooking } className="akdbw-text-center akdbw-py-10">
                <ActionButton
                    submit={ true }
                    uppercase={ true }
                    onClick={ () => {} }
                    className="akdbw-py-4 akdbw-px-10 akdbw-font-bold akdbw-text-lg"
                >
                    Book Now
                </ActionButton>
            </form>
        </div>
    )
}

export default SummaryPage;
