import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Service, ServiceOption } from "./services";
import { RootState } from "./store";
import { access } from "fs";

export type CartItem = {
    service: Service;
    option: ServiceOption;
    count: number;
};

export type CartState = {
    totalPrice: number
    items: CartItem[]
    handymanTotalPrice: number
    handymanItems: CartItem[]
    serviceDate: string
    serviceTime: string
		serviceType?: string
    location: {
        lat: number
        lng: number
    },
    locationName: string
    phone: string
    email: string
    paymentType: string
}

const CART_LOCAL_STORAGE_ID = 'AKDBW_CART_DATA'
const handymanApi = (window as any).onlineBookingWidgetCfg?.handyman;

const saveCart = (cart: CartState) => {
    localStorage.setItem(CART_LOCAL_STORAGE_ID, JSON.stringify(cart))
}

const loadCart = () => {
    const str = localStorage.getItem(CART_LOCAL_STORAGE_ID)
    if (str === null) {
        return null
    }
    return JSON.parse(str)
}

const savedCart = loadCart();

const initialState:CartState = savedCart === null ? {
    totalPrice: 0,
    items: [],
    handymanTotalPrice: 0,
    handymanItems: [],
    serviceDate: '',
    serviceTime: '',
    location: { lat: 25.090235, lng: 55.1658491 },
    locationName: '',
    phone: '',
    email: '',
    paymentType: ''
} : savedCart

const calculateCartTotal = (state: CartState) => (
    state.items
        .map(itm => itm.count * itm.option.price)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
)

const calculateHandymanCartTotal = (state: CartState) => (
    state.handymanItems
        .map(itm => itm.count * itm.option.price)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
)

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        clearCart: (state) => {
            state.items = []
            state.handymanItems = []
            state.serviceDate = ''
            state.serviceTime = ''
            state.totalPrice = 0
            state.handymanTotalPrice = 0
            saveCart(state)
        },
        setPaymentType: (state, action: PayloadAction<string>) => {
            state.paymentType = action.payload;
            saveCart(state);
        },
        setPhone: (state, action: PayloadAction<string>) => {
            state.phone = action.payload;
            saveCart(state);
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
            saveCart(state);
        },
        setLocation: (state, action: PayloadAction<{lat: number, lng: number}>) => {
            state.location = action.payload;
            saveCart(state);
        },
        setLocationName: (state, action: PayloadAction<string>) => {
            state.locationName = action.payload;
            saveCart(state);
        },
        setServiceDate: (state, action: PayloadAction<string>) => {
            state.serviceDate = action.payload
            saveCart(state)
        },
        setServiceTime: (state, action: PayloadAction<string>) => {
            state.serviceTime = action.payload
            saveCart(state)
        },
        decreaseInCart: (state, action: PayloadAction<{ option: ServiceOption, handyman: boolean}>) => {
            const items = action.payload.handyman ? state.handymanItems : state.items,
                existing = items.find(itm => itm.option.id === action.payload.option.id)
            if (existing) {
                if (existing.count > 1) {
                    existing.count -= 1
                } else {
                    if (window.confirm('This will remove item from cart, are you sure?')) {
                        const newItems = items.filter(itm => itm.option.id !== action.payload.option.id)
                        if (action.payload.handyman) {
                            state.handymanItems = newItems
                        } else {
                            state.items = newItems
                        }
                    }
                }
            }
            state.totalPrice = calculateCartTotal(state)
            state.handymanTotalPrice = calculateHandymanCartTotal(state)
            saveCart(state)
        },
        increaseInCart: (state, action: PayloadAction<{ option: ServiceOption, handyman: boolean }>) => {
            const items = action.payload.handyman ? state.handymanItems : state.items,
                existing = items.find(itm => itm.option.id === action.payload.option.id)
            if (existing) {
                existing.count += 1
            }
            state.totalPrice = calculateCartTotal(state)
            state.handymanTotalPrice = calculateHandymanCartTotal(state)
            saveCart(state)
        },
        addToCart: (state, action: PayloadAction<{ service: Service, option: ServiceOption, handyman: boolean }>) => {
            const items = [{
                service: action.payload.service,
                option: action.payload.option,
                count: 1
            }];
            if (action.payload.handyman) {
                state.handymanItems = items;
            } else {
                state.items = items;
            }
            state.totalPrice = calculateCartTotal(state)
            state.handymanTotalPrice = calculateHandymanCartTotal(state)
            saveCart(state)
        }
    }
});

export const {
    addToCart,
    increaseInCart,
    decreaseInCart,
    setServiceDate,
    setServiceTime,
    setLocation,
    setLocationName,
    setPhone,
    setEmail,
    setPaymentType,
    clearCart
} = cartSlice.actions
export const selectWholeCart = (state: RootState) => state.cart
export const selectCartTotal = (state: RootState) => state.cart.totalPrice
export const selectCartItems = (state: RootState) => state.cart.items
export const selectHandymanCartTotal = (state: RootState) => state.cart.handymanTotalPrice
export const selectHandymanCartItems = (state: RootState) => state.cart.handymanItems
export const selectServiceDate = (state: RootState) => state.cart.serviceDate
export const selectServiceTime = (state: RootState) => state.cart.serviceTime
export const selectLocationName = (state: RootState) => state.cart.locationName
export const selectLocation = (state: RootState) => state.cart.location
export const selectEmail = (state: RootState) => state.cart.email
export const selectPhone = (state: RootState) => state.cart.phone
export const selectPaymentType = (state: RootState) => state.cart.paymentType
