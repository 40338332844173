import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Service, ServiceOption } from "../store/services";
import { faStar } from '@fortawesome/free-solid-svg-icons'
import './service_info.css';
import clsx from "clsx";
import { DayAndNightIcon } from "../icons/DayAndNightIcon";
import YoutubeEmbed from "../shared/YoutubeEmbed";
import { ReactNode, useState } from "react";
import { ServiceTimeIcon } from "../icons/ServiceTimeIcon";
import ActionButton from "../shared/ActionButton";
import { CustomersIcon } from "../icons/CustomersIcon";
import { useAppDispatch } from "../hooks";
import { addToCart } from "../store/cart";
import Modal from "../layout/Modal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isMobileApp } from "../shared/isHandymanMode";

type ServiceInfoParams = {
    service: Service,
    handyman: boolean
};

type Open24HrsProps = {
    className?: string,
    iconClassName?: string
}

const Open24Hrs = ({ className, iconClassName }: Open24HrsProps) => (
    <div className={clsx(
        "akdbw-flex akdbw-flex-row akdbw-pb-8 akdbw-items-center",
        className
    )}>
        <DayAndNightIcon className={ iconClassName }/>
        <div className="akdbw-pl-2">Open 24 hours</div>
    </div>
);

const ServiceInfo = ( { service, handyman }: ServiceInfoParams ) => {
    const stars: ReactNode[] = [],
        [showServiceDetails, setShowServiceDetails] = useState(0),
        dispatch = useDispatch(),
        navigate = useNavigate()

    const doAddToCart = (option: ServiceOption) => {
        dispatch(
            addToCart({ service, option, handyman })
        )
        navigate(isMobileApp() ? '/profile/service_details' : '/service_details')
    };

    for (let i = 0; i < Math.round(service.rating); i++) {
        stars.push(<FontAwesomeIcon key={i} icon={faStar} className="akdbw-text-yellow" />);
    }

    return (
        <div className={ `akdbw-py-10 akdbw-mb-3 akdbw-border-t akdbw-border-black js-service-block-${service.id}` }>
            <div className="akdbw-pb-5 akdbw-px-3">
                <div className="akdbw-text-2xl akdbw-font-bold">{ service.name }</div>
                <Open24Hrs className="akdbw-text-sm"/>
                { (service.video_code !== null && service.video_code !== '') && <YoutubeEmbed url={ service.video_code } /> }
            </div>
            { service.options.map(option => (
                <div key={ option.id } className="akdbw-px-3 akdbw-pt-5 akdbw-mb-2 akdbw-shadow-lg akdbw-border-t akdbw-border-b akdbw-border-border_gray akdbw-bg-[#F6F7FC]">
                    <div className="akdbw-flex akdbw-flex-row akdbw-border-b akdbw-border-black akdbw-pb-3">
                        <div className="akdbw-w-8/12 akdbw-flex akdbw-flex-col akdbw-justify-between">
                            <div>
                                <div className="akdbw-text-xl akdbw-font-bold">{ option.name }</div>
                                <div className="akdbw-text-sm akdbw-flex akdbw-flex-row akdbw-py-1">
                                    <div>
                                        { stars } <span className="akdbw-text-black">{ service.rating }</span>
                                        <Open24Hrs className="akdbw-text-xs akdbw-py-2" iconClassName="akdbw-scale-75"/>
                                    </div>
                                    <div className="akdbw-flex akdbw-flex-row akdbw-text-xs akdbw-pl-3 akdbw-pt-[2px]">
                                        <ServiceTimeIcon/>
                                        <div>Service <span className="akdbw-whitespace-nowrap">{ option.service_time } min</span></div>
                                    </div>
                                </div>
                            </div>
                            <ul className="akdbw-text-sm">
                                <div dangerouslySetInnerHTML={{__html: option.description}}></div>
                            </ul>
                        </div>
                        <div className="akdbw-w-4/12 akdbw-ml-3 akdbw-flex akdbw-flex-col akdbw-justify-between">
                            <div className="akdbw-font-bold akdbw-text-center akdbw-text-lg">
                                { option.warranty_months } Months warranty
                            </div>
                            <img src={ service.img } className="akdbw-max-w-[130px] akdbw-mt-5 akdbw-mx-auto" alt="" />
                        </div>
                    </div>
                    <div className="akdbw-flex akdbw-flex-row akdbw-border-b akdbw-py-2">
                        <div className="akdbw-w-1/2 md:akdbw-w-8/12 akdbw-pr-2">
                            <div className="akdbw-flex akdbw-flex-col akdbw-justify-between akdbw-h-full text-center">
                                <div className="akdbw-inline-block">
                                    <div className="akdbw-flex akdbw-flex-row akdbw-items-center">
                                        <CustomersIcon />
                                        <span className="akdbw-text-sm akdbw-pr-2">{ option.booking_count / 1000 }k</span>
                                        Bookings
                                    </div>
                                </div>
                                <ActionButton
                                    onClick={ () => setShowServiceDetails(showServiceDetails === option.id ? 0 : option.id) }
                                    inverse={ true }
                                    noBorder={ true }
                                    className="akdbw-mt-5"
                                >
                                    See all Details
                                </ActionButton>
                            </div>
                        </div>
                        <div className="akdbw-w-1/2 md:akdbw-w-4/12 akdbw-flex akdbw-flex-col akdbw-justify-between akdbw-pl-2">
                            { option.old_price > 0 &&
                                <div className="akdbw-text-center akdbw-line-through akdbw-px-3 akdbw-text-dark_gray">{ option.old_price } AED</div>
                            }
                            <div className="akdbw-text-center akdbw-font-bold akdbw-px-4 akdbw-py-1 akdbw-text-lg akdbw-nowrap">
                                { option.price } AED
                            </div>
                            <ActionButton
                                onClick={ () => doAddToCart(option) }
                                uppercase={ true }
                                className="akdbw-w-full akdbw-mt-4"
                            >
                                Book
                            </ActionButton>
                        </div>
                    </div>
                </div>
            )) }
            <Modal
                visible={ showServiceDetails !== 0 }
                onClose={ () => setShowServiceDetails(0) }
                fullscreen={ true }
                noPadding={ true }
            >
                <div>
                    <div dangerouslySetInnerHTML={{__html: service.service_details}}></div>
                </div>
            </Modal>
        </div>
    );
};

export default ServiceInfo;