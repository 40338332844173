import { HomeServiceIcon } from "../shared/HomeServiceIcon"

const HomePage = () => {

    return (
        <div className="akdbw-h-full akdbw-w-full akdbw-text-center akdbw-pt-10">
            <div className="akdbw-font-bold akdbw-text-2xl akdbw-text-green">SERVICES</div>
            <div className="akdbw-grid akdbw-grid-cols-3 akdbw-gap-4 akdbw-mt-6">
                <HomeServiceIcon icon="home_icons/pestcontrol.png" title="Pest Control" action="/profile/new_booking" />
                <HomeServiceIcon icon="home_icons/aircon.png" title="AC Duct Cleaning/Repairing" action="/profile/new_booking?handyman=ac" />
                <HomeServiceIcon icon="home_icons/grooming.png" title="Pet Grooming" action="/profile/new_booking?handyman=grooming" />
                <HomeServiceIcon icon="home_icons/electric.png" title="Electrician" action="/profile/new_booking?handyman=electician" />
                <HomeServiceIcon icon="home_icons/handyman.png" title="HandyMan" action="/profile/new_booking?handyman=handyman" />
                <HomeServiceIcon icon="home_icons/maids.png" title="Maids" action="/profile/new_booking?handyman=maids" />
                <HomeServiceIcon icon="home_icons/sanitary.png" title="Sanitary" action="/profile/new_booking?handyman=sanitary" />
            </div>
        </div>
    )
}

export default HomePage
