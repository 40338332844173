import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useCheckTokenQuery, useListBookingsQuery } from '../store/bookings'
import { LoadingIndicator } from './LoadingIndicator'
import { getLoginToken } from '../utils/tokenStorage'
import LoggedInSidebar from './LoggedInSidebar';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { faChevronLeft, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HomeIcon } from '../icons/HomeIcon';
import { HomeIconActive } from '../icons/HomeIconActive';
import { MessagesIcon } from '../icons/MessagesIcon';
import { AccountIcon } from '../icons/AccountIcon';
import { AccountIconActive } from '../icons/AccountIconActive';
import { isMobileApp } from './isHandymanMode';
import { AkkadLogoNoPadding } from "./Logo";

const disableBooking = (window as any).onlineBookingWidgetCfg?.disableBooking
const mobileApp = isMobileApp()

const ProtectedRoute = () => {
    const token = getLoginToken(),
        navigate = useNavigate(),
        [expandedSidebar, setExpandedSidebar] = useState(false),
        location = useLocation(),
        { refetch } = useListBookingsQuery()

    useEffect(() => {
        if (location.pathname === '/profile/my_bookings') {
            refetch();
        }
    }, [location])

    const { data, isLoading, isError } = useCheckTokenQuery(token as string)

    useEffect(() => {
        if (token === null || data?.success === false) {
            navigate('/login');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.success, token])

    if (isLoading) {
        return <LoadingIndicator/>
    }

    if (isError) {
        localStorage.clear()
        window.location.reload()
        return null;
    }

    return (
        <div
            className={ clsx(
                "akdbw-container akdbw-mx-auto akdbw-h-full",
                !mobileApp && 'akdbw-pt-5'
            )}
        >
            <div className="akdbw-flex akdbw-flex-col md:akdbw-flex-row akdbw-h-full">
                { !mobileApp && (
                    <div className="akdbw-w-full md:akdbw-w-3/12 md:akdbw-px-3 akdbw-shadow akdbw-bg-white akdbw-p-5">
                        <LoggedInSidebar />
                    </div>
                )}
                <div
                    className={ clsx(
                        "akdbw-w-full md:akdbw-w-9/12 md:akdbw-px-3 akdbw-h-full",
                        mobileApp && 'akdbw-pt-[40px]'
                    )}
                >
                    { mobileApp &&
                        <>
                            <div className="topbar akdbw-w-full akdbw-fixed akdbw-left-0 akdbw-bg-green akdbw-z-50">
                                <div className="akdbw-py-2 akdbw-px-5 akdbw-flex akdbw-flex-row akdbw-items-center akdbw-shadow akdbw-border-b akdbw-border-light_gray">
                                    { ['/profile/home', '/profile/my_bookings', '/profile/edit'].indexOf(location.pathname) < 0 &&
                                        <div className="" onClick={ () => navigate(-1) }>
                                            <FontAwesomeIcon icon={ faChevronLeft } className="akdbw-text-white akdbw-pr-4" />
                                        </div>
                                    }
                                    <div className="akdbw-pl-3 akdbw-font-bold akdbw-text-xl akdbw-flex-1 akdbw-text-left akdbw-text-white">
                                        <img src={ AkkadLogoNoPadding } className="akdbw-h-[40px]"/>
                                    </div>
                                    <div
                                        className="akdbw-w-[40px] akdbw-h-[40px] akdbw-text-center akdbw-leading-[40px] akdbw-rounded-md"
                                        onClick={ () => navigate('/profile/edit') }
                                    >
                                        <FontAwesomeIcon
                                            icon={ faUser }
                                            className="akdbw-text-white"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="akdbw-fixed akdbw-w-full akdbw-bottom-0 akdbw-left-0 akdbw-py-6 akdbw-shadow-[rgba(0,0,15,0.5)_0px_-5px_10px_-6px] akdbw-z-50 akdbw-bg-green akdbw-text-white akdbw-content-evenly akdbw-justify-around akdbw-text-center akdbw-grid akdbw-grid-cols-3">
                                <div onClick={ () => navigate('/') }>
																	 { location.pathname === '/' || location.pathname === '/profile/home' ?
																		  <HomeIconActive className="akdbw-mx-auto" /> :
                                   		<HomeIcon className="akdbw-mx-auto" />
																	 }
                                   <span>Home</span>
                                </div> 
                                <div onClick={ () => window.open('https://wa.me/+9710504001344') }>
                                    <MessagesIcon className="akdbw-mx-auto" />
                                    <span>Messages</span>
                                </div>
                                <div onClick={ () => navigate('/profile/my_bookings') }>
																		{ location.pathname === '/profile/my_bookings' ?
																			<AccountIconActive className="akdbw-mx-auto akdbw-h-[30px]" /> :
																			<AccountIcon className="akdbw-mx-auto akdbw-h-[30px]" />
																		}
                                    <span>My bookings</span>
                                </div>
                            </div>
                        </>
                    }
                    <div className={ clsx(
                        "akdbw-p-5 akdbw-shadow akdbw-bg-white akdbw-min-h-full",
                        mobileApp && 'akdbw-pb-28 akdbw-pt-10'
                    ) }>
                        <Outlet />
                        <div className='akdbw-clear-both'></div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProtectedRoute;
