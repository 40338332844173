import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../config";
import { CartState } from "./cart";
import { getLoginToken } from "../utils/tokenStorage";

type SuccessApiResponse = {
    success: boolean
    token?: string
    message?: string
}

type CreateTokenRequest = {
    login_code: string
    phone: string
}

type CorpLoginRequest = {
    username: string
    password: string
}

export type Booking = {
    id: number
    location_name: string
    location: string
    phone: string
    email: string
    payment: string
    price: number
    vat: number
    price_with_vat: number
    start: string
    rescheduled: boolean
    booking_items: {
        count: number
        service: string
        service_id: number
        option: string
        option_id: number
        price: number
        warranty: number
        image: string
    }[]
    event_id: number
    event?: {
        id: number
        start: string
        email: string
        phone: string
        job_status: string
        p_ants: number
        p_flying: number
        p_bed_bugs: number
        p_cockroches: number
        p_mice: number
        p_ticks: number
        p_crickets: number
        p_rats: number
        p_fleas: number
        p_silverfish: number
        location: string
        price: number
        vat: number
        price_with_vat: number
        online_payment_status: string | null
        payment_card_number: string
        receipt_url: string
    }
}

export type UserProfileResponse = {
    user: {
        email: string
        phone: string
        username: boolean
        is_corporate: boolean
    } | null
}

type UpdateProfileParams = {
    email: string
    phone: string
    code: string
    password?: string
}

export const bookingsApi = createApi({
    reducerPath: 'bookingsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getLoginToken()
            if (token) {
              headers.set('Token', token)
            }
            return headers
        }
    }),
    tagTypes: ['Bookings', 'HandymanBookings', 'Profile'],
    endpoints: (builder) => ({
        rescheduleBooking: builder.mutation<SuccessApiResponse, Partial<Booking>>({
            query: (data) => ({
                url: 'reschedule',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Bookings']
        }),
        createLoginCode: builder.mutation<SuccessApiResponse, Partial<CartState>>({
            query: (data) => ({
                url: 'send_code',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Bookings']
        }),
        createToken: builder.mutation<SuccessApiResponse, CreateTokenRequest>({
            query: (data) => ({
                url: 'confirm_phone',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Bookings']
        }),
        corpLogin: builder.mutation<SuccessApiResponse, CorpLoginRequest>({
            query: (data) => ({
                url: 'corp_login',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Bookings']
        }),
        createBooking: builder.mutation<SuccessApiResponse, Partial<CartState>>({
            query: (data) => ({
                url: 'create_booking',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Bookings']
        }),
        createHandymanBooking: builder.mutation<SuccessApiResponse, Partial<CartState>>({
            query: (data) => ({
                url: 'create_handyman_booking',
                method: 'POST',
                body: { ...data, serviceType: localStorage.getItem('handyman_type') || '' }
            }),
            invalidatesTags: ['HandymanBookings']
        }),
        checkToken: builder.query<SuccessApiResponse, string>({
            query: (token) => `verify_token?token=${token}`
        }),
        listBookings: builder.query<Booking[], void>({
            query: () => 'bookings',
            providesTags: ['Bookings']
        }),
        listHandymanBookings: builder.query<Booking[], void>({
            query: () => 'handyman_bookings',
            providesTags: ['HandymanBookings']
        }),
        cancelBooking: builder.mutation<SuccessApiResponse, number>({
            query: (id) => ({
                url: 'cancel',
                method: 'POST',
                body: {
                    id
                }
            }),
            invalidatesTags: ['Bookings']
        }),
        cancelHandymanBooking: builder.mutation<SuccessApiResponse, number>({
            query: (id) => ({
                url: 'handyman_cancel',
                method: 'POST',
                body: {
                    id
                }
            }),
            invalidatesTags: ['HandymanBookings']
        }),
        getProfile: builder.query<UserProfileResponse, void>({
            query: () => 'user_profile',
            providesTags: ['Profile']
        }),
        createUpdateProfileCode: builder.mutation<SuccessApiResponse, void>({
            query: () => ({
                url: 'update_user_profile_code',
                method: 'POST'
            })
        }),
        updateProfile: builder.mutation<SuccessApiResponse, UpdateProfileParams>({
            query: (data) => ({
                url: 'update_user_profile',
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Profile']
        }),
        deleteProfile: builder.mutation<SuccessApiResponse, void>({
            query: () => ({
                url: 'delete_account',
                method: 'POST'
            })
        })
    })
})

export const {
    useCreateLoginCodeMutation,
    useCreateTokenMutation,
    useCreateBookingMutation,
    useCreateHandymanBookingMutation,
    useCheckTokenQuery,
    useListBookingsQuery,
    useListHandymanBookingsQuery,
    useCancelBookingMutation,
    useCancelHandymanBookingMutation,
    useGetProfileQuery,
    useCreateUpdateProfileCodeMutation,
    useUpdateProfileMutation,
    useRescheduleBookingMutation,
    useDeleteProfileMutation,
    useCorpLoginMutation
} = bookingsApi
