// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ROOT } from '../config';

export type SliderItem = {
    id: number;
    text: string;
    img: string;
    sort: number;
};

export const slidersApi = createApi({
    reducerPath: 'slidersApi',
    baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
    endpoints: (builder) => ({
        getSliders: builder.query<SliderItem[], void>({
            query: () => 'sliders'
        }),
        getHandymanSliders: builder.query<SliderItem[], void>({
            query: () => 'handyman_sliders'
        })
    })
});

export const { useGetSlidersQuery, useGetHandymanSlidersQuery } = slidersApi;