import clsx from "clsx";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Cart from "../checkout/Cart";
import CartTotal from "../checkout/CartTotal";
import { useAppSelector } from "../hooks";
import Layout from "../layout/Layout";
import ServiceList from "../services/ServiceList";
import ServiceSelector from "../services/ServiceSelector";
import IndexSlider from "../shared/IndexSlider";
import { isHandymanMode, isMobileApp } from "../shared/isHandymanMode";
import { selectCartItems, selectHandymanCartItems } from "../store/cart";

const StartPage = () => {
    const pestcontrolCartItems = useAppSelector(selectCartItems),
        handymanCartItems = useAppSelector(selectHandymanCartItems),
        [params] = useSearchParams(),
        ht = params.get('handyman'),
        handymanMode = isMobileApp() ? (ht !== null) : isHandymanMode(),
        cartItems = handymanMode ? handymanCartItems : pestcontrolCartItems,
        mobileApp = isMobileApp()

    useEffect(() => {
        localStorage.setItem('handyman_type', ht === null ? '' : ht)
    }, [])
    
    return (
        <div className={clsx(
            "akdbw-pb-10 akdbw-pt-5 akdbw-px-5 md:akdbw-py-10",
            (mobileApp && cartItems.length > 0) && 'akdbw-pb-20'
        )}>
            <div className="akdbw-max-w-[700px] akdbw-mx-auto">
                <div className="akdbw-border-b akdbw-border-black akdbw-text-center akdbw-py-3">
                    <div className="akdbw-text-2xl akdbw-font-bold">Pest Control</div>
                    <div>Common Insects Issue solution</div>
                </div>
                <div className="akdbw-p-5">
                    <ServiceSelector handymanMode={ handymanMode } />
                </div>
            </div>
        </div>
    )
}


export default StartPage;