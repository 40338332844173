import { Provider } from 'react-redux';
import { store } from './store/store';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import StartPage from './pages/StartPage';
import ServiceDetailsPage from './pages/ServiceDetailsPage';
import DateTimePage from './pages/DateTimePage';
import LocationPage from './pages/LocationPage';
import SummaryPage from './pages/SummaryPage';
import PaymentPage from './pages/PaymentPage';
import { OrderCreatedPage } from './pages/OrderCreatedPage';
import { MyBookingsPage } from './pages/MyBookingsPage';
import ProtectedRoute from './shared/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import ProfilePage from './pages/ProfilePage';
import ProfileRedirect from './shared/ProfileRedirect';
import HomePage from './pages/HomePage';
import { getLoginToken } from './utils/tokenStorage';
import { isMobileApp } from './shared/isHandymanMode';
import SelectServiceTypePage from './pages/SelectServiceTypePage';

const disableBooking = (window as any).onlineBookingWidgetCfg?.disableBooking
const mobileApp = isMobileApp()

const bookingRoutes = disableBooking ? [{
    path: "/",
    element: <ProfileRedirect/>
}] :[
    {
        path: "/",
        element: mobileApp ? <ProfileRedirect /> : <StartPage />
    },
    {
        path: '/service_details',
        element: <ServiceDetailsPage />
    },
    {
        path: '/select_service_type/:id',
        element: <SelectServiceTypePage />
    },
    {
        path: '/date_time',
        element: <DateTimePage />
    },
    {
        path: '/location',
        element: <LocationPage />
    },
    {
        path: '/payment',
        element: <PaymentPage />
    },
    {
        path: '/summary',
        element: <SummaryPage />
    },
    {
        path: '/order_created',
        element: <OrderCreatedPage />
    }
]

const router = createHashRouter([
    ...bookingRoutes,
    {
        path: '/login',
        element: <LoginPage />
    },
    {
        path: '/profile',
        element: <ProtectedRoute />,
        children: [{
            path: '/profile/my_bookings',
            element: <MyBookingsPage />
        }, {
            path: '/profile/edit',
            element: <ProfilePage />
        }, {
            path: '/profile/new_booking',
            element: <StartPage />
        }, {
            path: '/profile/select_service_type/:id',
            element: <SelectServiceTypePage />
        }, {
            path: '/profile/home',
            element: <HomePage />
        }, {
            path: '/profile/service_details',
            element: <ServiceDetailsPage />
        }, {
            path: '/profile/date_time',
            element: <DateTimePage />
        }, {
            path: '/profile/location',
            element: <LocationPage />
        }, {
            path: '/profile/payment',
            element: <PaymentPage />
        }, {
            path: '/profile/summary',
            element: <SummaryPage />
        }, {
            path: '/profile/order_created',
            element: <OrderCreatedPage />
        }]
    }
]);

const App = () => {
    const token = getLoginToken()

    return (
        <Provider store={ store }>
            <RouterProvider router={router} />
        </Provider>
    )
}

export default App;
