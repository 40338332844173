import { useState } from "react";
import clsx from "clsx";
import { BookingsList } from "../shared/BookingsList";
import { isHandymanMode, isMobileApp } from "../shared/isHandymanMode";

export const MyBookingsPage = () => {
    const [handymanMode, setHandymanMode] = useState(false)

    if (!isMobileApp()) {
        return <BookingsList handymanMode={ isHandymanMode() } />
    }

    return (
        <div className="akdbw-mt-6">
            <div className="akdbw-flex akdbw-flex-row akdbw-justify-center akdbw-mb-8">
                <button
                    className={ clsx(
                        'akdbw-border akdbw-border-green akdbw-px-4 akdbw-py-2 akdbw-rounded-l-md',
                        !handymanMode && 'akdbw-bg-green akdbw-text-white'
                    ) }
                    onClick={ () => setHandymanMode(false) }
                >
                    Pest Control
                </button>
                <button
                    className={ clsx(
                        'akdbw-border akdbw-border-green akdbw-px-4 akdbw-py-2 akdbw-ml-[-1px] akdbw-rounded-r-md',
                        handymanMode && 'akdbw-bg-green akdbw-text-white'
                    ) }
                    onClick={ () => setHandymanMode(true) }
                >
                    Other services
                </button>
            </div>
            <BookingsList handymanMode={ handymanMode } />
        </div>    
    );
};
