import { configureStore } from "@reduxjs/toolkit";
import { servicesApi } from "./services";
import { slidersApi } from "./sliders";
import { bookingsApi } from "./bookings"
import { cartSlice } from "./cart";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
    reducer: {
        [bookingsApi.reducerPath]: bookingsApi.reducer,
        [servicesApi.reducerPath]: servicesApi.reducer,
        [slidersApi.reducerPath]: slidersApi.reducer,
        cart: cartSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
        servicesApi.middleware,
        slidersApi.middleware,
        bookingsApi.middleware
    )
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;