import { IconProps } from "./IconProps";

export const AccountIcon = ({ className }: IconProps) => (
	<svg fill="#fff" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 183.199 183.199" className={ className }>
		<g>
			<g>
				<g>
					<path d="M35.069,5.875H3.897C1.743,5.875,0,7.62,0,9.772v23.379c0,2.152,1.743,3.897,3.897,3.897h31.172
						c2.154,0,3.897-1.745,3.897-3.897V9.772C38.966,7.62,37.223,5.875,35.069,5.875z M31.172,29.255H7.793V13.668h23.379V29.255z"/>
					<path d="M35.069,76.013H3.897C1.743,76.013,0,77.758,0,79.91v23.379c0,2.152,1.743,3.897,3.897,3.897h31.172
						c2.154,0,3.897-1.745,3.897-3.897V79.91C38.966,77.758,37.223,76.013,35.069,76.013z M31.172,99.392H7.793V83.806h23.379V99.392z
						"/>
					<path d="M35.069,146.151H3.897c-2.154,0-3.897,1.745-3.897,3.897v23.379c0,2.152,1.743,3.897,3.897,3.897h31.172
						c2.154,0,3.897-1.745,3.897-3.897v-23.379C38.966,147.896,37.223,146.151,35.069,146.151z M31.172,169.53H7.793v-15.586h23.379
						V169.53z"/>
					<rect x="50.655" y="17.565" width="132.544" height="7.793"/>
					<rect x="50.655" y="87.703" width="132.544" height="7.793"/>
					<rect x="50.655" y="157.841" width="132.544" height="7.793"/>
				</g>
			</g>
		</g>
	</svg>
)
