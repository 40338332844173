import { useAppDispatch, useAppSelector } from "../hooks";
import BottomButtons from "../shared/BottomButtons";
import CheckoutProgress from "../shared/CheckoutProgress";
import { selectServiceDate, selectServiceTime, setServiceDate, setServiceTime } from "../store/cart";
import CheckoutLayout from "../layout/CheckoutLayout";
import dayjs from "dayjs";
import DateTimeSelection from "../shared/DateTimeSelection";
import { isMobileApp } from "../shared/isHandymanMode";

const mobileApp = isMobileApp()

const DateTimePage = () => {
    const serviceDate = useAppSelector(selectServiceDate),
        serviceTime = useAppSelector(selectServiceTime),
        dispatch = useAppDispatch(),
        serviceDt = dayjs(serviceDate),
        today = dayjs(),
        dateValid = serviceDt.isAfter(today)

    return (
        <div>
            <CheckoutProgress step={ 2 } />
            <CheckoutLayout>
                <DateTimeSelection
                    selectedDate={ serviceDate }
                    selectedTime={ serviceTime }
                    onDateSelected={ v => dispatch( setServiceDate(v) ) }
                    onTimeSelected={ v => dispatch( setServiceTime(v) ) }
                />
            </CheckoutLayout>
            <BottomButtons
                nextPageUrl={ mobileApp ? '/profile/location' : '/location' }
                prevPageUrl={ mobileApp ? '/profile/home' : '/home' }
                stepInvalid={ !dateValid || serviceDate === '' || serviceTime === '' }
            />
        </div>
    )
};

export default DateTimePage;
