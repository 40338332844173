import clsx from "clsx"

type HeadingProps = {
    size: number
    children: React.ReactNode
    className?: string
}

const Heading = ({ children, size, className }: HeadingProps) => {
    return (
        <div className={clsx(
            "akdbw-font-semibold",
            className,
            size === 3 && 'akdbw-text-lg',
            size === 4 && 'akdbw-text-md'
        )}>
            { children }
        </div>
    )
}

export default Heading