import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { isMobileApp } from "./isHandymanMode"

const mobileApp = isMobileApp()

const ProfileRedirect = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate(mobileApp ? '/profile/home' : '/profile/my_bookings')
    }, [])

    return <div></div>
}

export default ProfileRedirect