export const isMobileApp = () => (
	(window as any).onlineBookingWidgetCfg?.mobileApp
)

export const isHandymanMode = () => {
	if (isMobileApp()) {
		return false
	}

	return (window as any).onlineBookingWidgetCfg?.handyman
}

export const getSavedHandymanState = () => {
	const ht = localStorage.getItem('handyman_type')
	return (ht !== null && ht !== '')
}