import { FormEvent, useState } from "react"
import { useCorpLoginMutation, useCreateLoginCodeMutation, useCreateTokenMutation } from "../store/bookings"
import ActionButton from "../shared/ActionButton"
import { saveLoginToken } from "../utils/tokenStorage"
import { useNavigate } from "react-router-dom"
import { LoadingIndicator } from "../shared/LoadingIndicator"
import { AkkadLogo } from "../shared/Logo"
import clsx from "clsx"
import { isMobileApp } from "../shared/isHandymanMode"

const mobileApp = isMobileApp()

const LoginPage = () => {
    const [loginCodeCreated, setLoginCodeCreated] = useState(false),
        [phone, setPhone] = useState(''),
        [codeInput, setCodeInput] = useState(''),
        [createToken, tokenData] = useCreateTokenMutation(),
        [usernameLogin, corpTokenData] = useCorpLoginMutation(),
        [loginCodeError, setLoginCodeError] = useState(false),
        [createLoginCode, { isLoading }] = useCreateLoginCodeMutation(),
        [corpLogin, setCorpLogin] = useState(false),
        [corpLoginError, setCorpLoginError] = useState(false),
        [username, setUsername] = useState(''),
        [password, setPassword] = useState(''),
        navigate = useNavigate()

    const doLogin = async (ev: FormEvent) => {
        ev.preventDefault()
        const data = await createToken({
            phone,
            login_code: codeInput
        }).unwrap()
        setLoginCodeError(!data.success)
        if (data.success) {
            saveLoginToken(data.token || '')
            navigate(mobileApp ? '/profile/home' : '/profile/my_bookings')
        }
    }

    const doCorpLogin = async() => {
        const data = await usernameLogin({
            username,
            password
        }).unwrap()
        setCorpLoginError(!data.success)
        if (data.success) {
            saveLoginToken(data.token || '')
            navigate(mobileApp ? '/profile/home' : '/profile/my_bookings')
        }
    }

    const sendLoginCode = async (ev: FormEvent) => {
        ev.preventDefault()
        if (corpLogin) {
            setCorpLoginError(false)
            doCorpLogin()
        } else {
            await createLoginCode({
                phone
            }).unwrap()
            setLoginCodeCreated(true)
        }
    }

    if (isLoading || tokenData.isLoading) {
        return <LoadingIndicator />
    }

    return (
        <div className="akdbw-py-10 akdbw-text-center akdbw-h-full akdbw-flex akdbw-flex-col akdbw-justify-center">
            { mobileApp ?
                <img src={ AkkadLogo } alt="" className="akdbw-max-w-[50%] akdbw-mx-auto" /> :
                <div className="akdbw-text-2xl akdbw-font-bold">Login to system</div>
            }
            <div className="akdbw-p-5 akdbw-mx-auto akdbw-max-w-[400px]">
                { loginCodeCreated ? (
                    <>
                        <div className="akdbw-mt-10">We send code to your phone number {phone} via WhatsApp</div>
                        <div className="akdbw-font-bold akdbw-mt-5 akdbw-mb-3">Enter this code here to continue</div>
                        <form onSubmit={ doLogin }>
                            <input
                                type="text"
                                pattern="[0-9]*"
                                inputMode="numeric"
                                value={ codeInput }
                                onChange={ ev => setCodeInput(ev.target.value) }
                                className="akdbw-border akdbw-border-border_gray akdbw-p-2 akdbw-w-full akdbw-text-center akdbw-rounded-sm"
                            />
                            { loginCodeError &&
                                <div className="akdbw-py-2 akdbw-px-4 akdbw-bg-red-400 akdbw-text-white akdbw-border akdbw-border-red akdbw-rounded-md akdbw-mt-3">
                                    Code is invalid
                                </div>
                            }
                            <ActionButton
                                submit={ true }
                                uppercase={ true }
                                className="akdbw-mt-4"
                                onClick={ () => {} }
                            >
                                Login
                            </ActionButton>
                        </form>
                    </>
                ): (
                    <form onSubmit={ sendLoginCode } >
                        <div className="akdbw-border akdbw-border-green akdbw-flex akdbw-flex-row akdbw-text-center akdbw-just akdbw-w-full akdbw-rounded-md akdbw-bg-white">
                            <div
                                className={ clsx("akdbw-w-1/2 akdbw-px-7 akdbw-py-3", !corpLogin ? 'akdbw-bg-green akdbw-text-white' : 'akdbw-cursor-pointer') }
                                onClick={ () => setCorpLogin(false) }
                            >Individual</div>
                            <div
                                className={ clsx("akdbw-w-1/2 akdbw-px-7 akdbw-py-3", corpLogin ? 'akdbw-bg-green akdbw-text-white akdbw-cursor-default' : 'akdbw-cursor-pointer') }
                                onClick={ () => setCorpLogin(true) }
                            >Company</div>
                        </div>
                        { corpLogin ? 
                            <>
                                { corpLoginError &&
                                    <div className="akdbw-py-2 akdbw-px-4 akdbw-bg-red-400 akdbw-text-white akdbw-border akdbw-border-red akdbw-rounded-md akdbw-mt-3">
                                        Username or password is incorrect
                                    </div>
                                }
                                <div className="akdbw-mt-10 akdbw-mb-10">Enter your username and password</div>
                                <input
                                    type="text"
                                    value={ username }
                                    onChange={ ev => setUsername(ev.target.value) }
                                    placeholder="Username..."
                                    className="akdbw-border akdbw-border-border_gray akdbw-p-2 akdbw-w-full akdbw-text-center akdbw-rounded-sm"
                                />
                                <input
                                    type="password"
                                    value={ password }
                                    onChange={ ev => setPassword(ev.target.value) }
                                    placeholder="Password..."
                                    className="akdbw-border akdbw-border-border_gray akdbw-p-2 akdbw-w-full akdbw-text-center akdbw-rounded-sm akdbw-mt-4"
                                />
                            </> :
                            <>
                                <div className="akdbw-mt-10 akdbw-mb-10">Enter your phone number to login</div>
                                <input
                                    type="text"
                                    value={ phone }
                                    placeholder="0501234567"
                                    onChange={ ev => setPhone(ev.target.value) }
                                    className="akdbw-border akdbw-border-border_gray akdbw-p-2 akdbw-w-full akdbw-text-center akdbw-rounded-sm"
                                />
                            </>
                        }
                        <ActionButton
                            submit={ true }
                            uppercase={ true }
                            className="akdbw-mt-4"
                            onClick={ () => {} }
                        >
                            { corpLogin ? 'Login' : 'Send code' }
                        </ActionButton>
                    </form>
                )}
            </div>
        </div>
    )
}

export default LoginPage